<template>
  <div class="ToolContainer">

    <div class="crop-box"
         @mousedown="onMouseDown"
         @mouseup="onMouseUp"
         @mousemove="dragPosition"
         ref="draggableContainer"
         :style="{
              width: imageSize + '%'
            }"

    >
      <img :src="frontImage"
           alt="Random image"
           class="unselectable image"
           :style="{
              opacity: imageOpacity + '%',
              transform: 'rotate(' + imageRotation+ 'deg)',
              width: '100%'
            }"
      >
    </div>
    
        <div class="Image1">
    <img :src="background" alt="Random image" style="width: 100%;">
    </div> 

    <div class="Tools">
        <div>
          Image width {{imageSize}}
        </div>
        <div>
          <label for="image_opacity">Opacity</label>
          <input type="range" min="0" max="100" id="image_opacity" v-model="imageOpacity">
        </div>
        <div>
          <label for="image_rotation">Rotation</label>
          <input type="range" min="0" max="360" id="image_rotation" v-model="imageRotation">
        </div>
        <div>
          <label for="image_size">Size</label>
          <input type="range" min="0" max="100" id="image_size" v-model="imageSize">
        </div>
    </div>  

 

  </div>
</template>

<script>
export default {
  name: "ImageCropper",
  props: ['background', 'frontImage'],
  data: function (){
    return {
      editMode: "",
      imageOpacity: 100,
      imageSize: 100,
      imageRotation: 0,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      xPositionAbsolute: 0,
      yPositionAbsolute: 0,
      xPos: 0,
      yPos: 0,
      leftPos: 0,
      topPos: 0,
      xOffset: 0,
      yOffset: 0,
      isMoving: false,
    }
  },
  methods: {
    onMouseDown(event) {
      this.isMoving = true
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
    },
    onMouseUp() {
      this.isMoving = false
    },
    dragPosition(event) {
      if(this.isMoving) {
        this.positions.movementX = this.positions.clientX - event.clientX
        this.positions.movementY = this.positions.clientY - event.clientY
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY

        this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
        this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'

      }
    }
  },
  computed: {
    // xPos() {
    //   return this.xPositionAbsolute - this.xOffset;
    // },
    // yPos() {
    //   return this.yPositionAbsolute - this.yOffset;
    // }
  }
}
</script>

<style scoped>

.image {
  display: block;
  height: auto;
}
.crop-box {
  position: absolute;
  left: 0;
  top: 0;
  cursor: move;
}


.unselectable {
  /* For Opera and <= IE9, we need to add unselectable="on" attribute onto each element */
  /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
  -moz-user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
  -webkit-user-select: none;
  -ms-user-select: none; /* From IE10 only */
  user-select: none; /* Not valid CSS yet, as of July 2012 */

  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
  user-drag: none;
}

input.radio { /* styles here */
  display:inline
}

</style>
