<template>
  <div>

    <div class="grid grid-cols-8 gap-4 p-10">
      

      <image-search-component class="col-start-1 col-end-3 bg-white border-solid border-2"
          @onImageClick="updateBackground"
          heading="Choose background"
      />

      <image-cropper class="col-start-3 col-end-7 border-solid border-2" :background="background" :front-image="forground"/>

      <image-search-component class="col-start-7 col-end-9 bg-white border-solid border-2"
          @onImageClick="updateForground"
          heading="Choose cropping image"

      />

      
    </div>
  </div>
</template>

<script>
import ImageCropper from "./components/ImageCropper";
import ImageSearchComponent from "./components/ImageSearchComponent";


export default {
  name: 'App',
  components: {
    ImageCropper,
    ImageSearchComponent,
  },
  methods: {
    updateBackground(url) {
      this.background = url.thumbnailUrl;
    },
    updateForground(url) {
      this.forground = url.thumbnailUrl;

    }
  },
  data() {
    return {
      background: '',
      forground: '',
    }
  }
}
</script>

<style>
</style>
