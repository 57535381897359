<template>
  <div class="test" >
    <div class="">
      <h2 class="">{{heading}}</h2>
      <input type="search" class="rounded" v-model="imageName">
      <button class="rounded-full" @click="searchImage">SEARCH</button>
    </div>

    <div class="grid grid-cols-2 gap-2 h-screen overflow-auto pt-20" style="">
      <img
          @click="$emit('onImageClick', url)"
          style="cursor: pointer"
          v-for="url in imageUrls" :key="url"
          alt="Qries" :src=url.thumbnailUrl>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageSearchComponent",
  props: ['heading'],
  methods: {
    searchImage() {
      const apiKey = "19036d0a247548cc9a3f4a63cf090337"
      const url = `https://api.bing.microsoft.com/v7.0/images/search?q=${this.imageName}+logo+black`
      const headers = {"Ocp-Apim-Subscription-Key": apiKey };
      fetch(url, {headers})
          .then(response => response.json())
          .then(images => {
            // console.log(images);
            this.imageUrls = images.value
          });
    }
  },
  data() {
    return {
      imageName: '',
      imageUrls: [],
    }
  }
}
</script>

<style scoped>

</style>
